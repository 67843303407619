.l-booking {
}

.l-booking.has-notification-bar {
	padding-top: 2.5rem;
}

.l-booking__header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: var(--z-index-site-header);
}

.l-booking__content-wrapper {
	background-color: var(--color-green);
	padding-top: 6.0625rem;
	padding-bottom: 3.125rem;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-booking__content-wrapper {
		padding-top: 13.562rem;
		padding-bottom: 7.5rem;
	}
}

.l-booking__content {
	box-sizing: border-box;
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-booking__content {
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-top: var(--space-40);
		padding-right: 3.75rem;
		padding-bottom: var(--space-40);
		padding-left: 3.75rem;
		justify-content: space-between;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-booking__content {
		padding: var(--space-48);
		width: 972px;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-booking__content {
		width: 1320px;
	}
}

/* up-to-small */
@media (max-width: 767px) {
	.l-booking__navigation {
		border-bottom: 1px solid var(--color-light-grey);
		padding: var(--space-40) var(--space-16);
	}

	.l-booking__main {
		padding: var(--space-40) var(--space-16);
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.l-booking__navigation {
		flex-basis: calc(calc(calc(100% - calc(9 * var(--column-gap))) / calc(10 / 2)) + calc(calc(2 - 1) * var(--column-gap)));
		flex-grow: 0;
		flex-shrink: 0;
	}

	.l-booking__main {
		flex-basis: calc(calc(calc(100% - calc(9 * var(--column-gap))) / calc(10 / 8)) + calc(calc(8 - 1) * var(--column-gap)));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-booking__navigation {
		flex-basis: calc(calc(calc(100% - calc(9 * var(--column-gap-from-large))) / calc(10 / 2)) + calc(calc(2 - 1) * var(--column-gap-from-large)));
	}

	.l-booking__main {
		flex-basis: calc(calc(calc(100% - calc(9 * var(--column-gap-from-large))) / calc(10 / 8)) + calc(calc(8 - 1) * var(--column-gap-from-large)));
	}
}

/* up-to-small */
@media (max-width: 767px) {
	.l-booking__main hr {
		margin-right: calc(-1 * var(--space-16));
		margin-left: calc(-1 * var(--space-16));
	}
}

.l-booking__footer {
}
