.theme-off-white {
	background-color: var(--color-off-white);
	color: var(--color-charcoal);
}

.theme-white {
	background-color: var(--color-white);
	color: var(--color-charcoal);
}

.theme-green-white {
	background-color: var(--color-green);
	color: var(--color-white);
}

.theme-green-black {
	background-color: var(--color-green-background-for-text);
	color: var(--color-black);
}

.theme-transparent-white {
	color: var(--color-white);
}

/* #region Only colors */
.theme-color-black {
	color: var(--color-black);
}
/* #endregion Only colors */
