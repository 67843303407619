.photo-gallery {
}

/* up-to-medium */
@media (max-width: 1023px) {
	.photo-gallery {
		margin-bottom: calc(-1 * var(--space-40));
	}
}

.photo-gallery__row {
}

.photo-gallery__row--side-by-side {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.photo-gallery__row--side-by-side {
		display: grid;
		grid-gap: var(--column-gap);
		grid-template-areas: "area-a area-a area-a area-a area-a area-a area-a area-a area-b area-c area-c area-c";
		grid-template-columns: repeat(12, 1fr);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.photo-gallery__row--side-by-side {
		grid-gap: var(--column-gap-from-large);
		grid-template-areas: "area-a area-a area-a area-a area-a area-a area-a area-a area-b area-c area-c area-c";
		grid-template-columns: repeat(12, 1fr);
	}
}

.photo-gallery__row--side-by-side .photo-gallery__row__spacer {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.photo-gallery__row--side-by-side .photo-gallery__row__spacer {
		grid-area: area-b;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.photo-gallery__row--side-by-side .photo-gallery__row__spacer {
		grid-area: area-b;
	}
}

.photo-gallery__row--centered {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.photo-gallery__row--centered {
		display: grid;
		grid-gap: var(--column-gap);
		grid-template-areas: "area-a area-a area-b area-b area-b area-b area-b area-b area-b area-b area-c area-c";
		grid-template-columns: repeat(12, 1fr);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.photo-gallery__row--centered {
		grid-gap: var(--column-gap-from-large);
		grid-template-areas: "area-a area-a area-b area-b area-b area-b area-b area-b area-b area-b area-c area-c";
		grid-template-columns: repeat(12, 1fr);
	}
}

.photo-gallery__row--centered .photo-gallery__row__spacer {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.photo-gallery__row--centered .photo-gallery__row__spacer.photo-gallery__row__spacer--before {
		grid-area: area-a;
	}

	.photo-gallery__row--centered .photo-gallery__row__spacer.photo-gallery__row__spacer--after {
		grid-area: area-c;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.photo-gallery__row--centered .photo-gallery__row__spacer.photo-gallery__row__spacer--before {
		grid-area: area-a;
	}

	.photo-gallery__row--centered .photo-gallery__row__spacer.photo-gallery__row__spacer--after {
		grid-area: area-c;
	}
}

.photo-gallery__item {
	color: var(--color-black);
	display: block;
	text-decoration: none;
}

/* up-to-medium */
@media (max-width: 1023px) {
	.photo-gallery__item {
		margin-bottom: var(--space-40);
	}
}

.photo-gallery__row:not(:last-child) .photo-gallery__item {
	margin-bottom: var(--space-40);
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.photo-gallery__item {
		display: flex;
		align-items: flex-end;
	}

	.photo-gallery__row--side-by-side .photo-gallery__item.photo-gallery__item--1 {
		grid-area: area-a;
		width: 100%;
	}

	.photo-gallery__row--side-by-side .photo-gallery__item.photo-gallery__item--2 {
		grid-area: area-c;
		width: 100%;
	}

	.photo-gallery__row--centered .photo-gallery__item.photo-gallery__item--1 {
		grid-area: area-b;
		width: 100%;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.photo-gallery__row:not(:last-child) .photo-gallery__item {
		margin-bottom: var(--space-80);
	}
}

.photo-gallery__item__image--portrait {
	width: 50%;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.photo-gallery__item__image--portrait {
		width: 100%;
	}
}

.photo-gallery__item__title {
	padding-top: var(--space-16);
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.photo-gallery__item__title {
		padding-top: var(--space-24);
	}
}

.photo-gallery__link {
	color: var(--color-black);
	text-decoration: none;
}
