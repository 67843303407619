.columns-50-50 {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.columns-50-50 {
		display: flex;
		justify-content: space-between;
	}
}

/* #region columns 50 50 item */

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.columns-50-50__item {
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap)));
		flex-grow: 0;
		flex-shrink: 0;
	}

	.columns-50-50__item--narrow {
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 4)) + calc(calc(4 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.columns-50-50__item {
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap-from-large)));
		flex-grow: 0;
		flex-shrink: 0;
	}

	.columns-50-50__item--narrow {
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 4)) + calc(calc(4 - 1) * var(--column-gap-from-large)));
	}
}

/* up-to-medium */
@media (max-width: 1023px) {
	.columns-50-50__item:first-child:not(:last-child) {
		margin-bottom: var(--space-32);
	}

	.columns-50-50__item.columns-50-50__item--empty:first-child {
		margin-bottom: 0;
	}
}
/* #endregion columns 50 50 item */

/* #region columns 50 50 two images */
.columns-50-50__two-images {
	display: flex;
	justify-content: space-between;
}

.columns-50-50__two-images__image {
	flex-basis: calc(calc(calc(100% - calc(5 * var(--column-gap))) / calc(6 / 3)) + calc(calc(3 - 1) * var(--column-gap)));
	flex-grow: 0;
	flex-shrink: 0;
}

/* from-large */
@media (min-width: 1440px) {
	.columns-50-50__two-images__image {
		flex-basis: calc(calc(calc(100% - calc(5 * var(--column-gap-from-large))) / calc(6 / 3)) + calc(calc(3 - 1) * var(--column-gap-from-large)));
	}
}
/* #endregion columns 50 50 two images */

/* #region column 50 50 text formatter for more narrow text */

/* from-large */
@media (min-width: 1440px) {
	.columns-50-50__wysiwyg .is-narrow-text {
		width: 66.66%;
	}
}
/* #endregion column 50 50 text formatter for more narrow text */
