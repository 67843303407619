.site-header {
	background-color: var(--color-off-white-alpha-95);
	border-bottom: 1px solid var(--color-light-grey);
	width: 100%;
	z-index: 1;
}

.site-header__inner {
	color: var(--color-charcoal);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* #region Site Header logo */
.site-header__logo {
	width: 5.3125rem;
	height: 1.5625rem;
}

/* up-to-medium */
@media (max-width: 1023px) {
	.site-header__logo {
		padding-top: 3px;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-header__logo {
		padding-bottom: 13px;
		width: 6.375rem;
	}
}
/* #region Site Header logo */

/* #region Site Header menus */
.site-header__menus {
	display: flex;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	align-items: center;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-header__menus {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.site-header__menus {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}
}
/* #endregion Site Header menus */

/* #region Site Header navigation */
.site-header__navigation {
	display: none;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-header__navigation {
		display: block;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		position: relative;
	}
}

.site-header__navigation:not(*:first-of-type)::before {
	background: var(--color-light-grey);
	content: "";
	display: block;
	position: absolute;
	top: 0.5rem;
	bottom: 0.5rem;
	left: 0;
	width: 1px;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-header__navigation__list {
		display: grid;
		font-family: var(--font-sans-with-serif-numbers);
		font-size: 0.75rem;
		font-stretch: normal;
		font-style: normal;
		font-weight: 400;
		grid-template-rows: repeat(2, auto);
		grid-template-columns: repeat(2, auto);
		letter-spacing: 0.03125rem;
		line-height: 1;
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

.site-header__navigation__list__item {
	box-sizing: border-box;
	grid-row: auto;
	grid-column: auto;
	list-style: none;
	padding: 0.5rem 1.25rem;
}

.site-header__navigation__list__item--current {
	text-decoration: underline;
}

.site-header__navigation__link {
	color: var(--color-charcoal);
	text-decoration: none;
	text-transform: uppercase;
	transition: color 96ms linear;
}

.site-header__navigation__link:hover {
	color: var(--color-black);
	text-decoration: underline;
}
/* #endregion Site Header navigation */

/* #region Site Header Book button */

/* up-to-medium */
@media (max-width: 1023px) {
	.site-header__book-cta .button {
		background-color: transparent;
		color: var(--color-charcoal);
		font-size: 0.75rem;
		text-transform: uppercase;
	}

	.site-header__book-cta .button:hover {
		background-color: transparent;
		color: var(--color-black);
	}
}

.site-header__menu-button {
	display: block;
}
/* #endregion Site Header Book button */

/* #region Site Header Hamburger button */

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-header__menu-button {
		display: none;
	}
}

.site-header__hamburger {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	margin-left: 0.752rem;
	padding: 0;
	position: relative;
	transition: color 96ms linear;
	width: 1.375rem;
	height: 2.1875rem;
}

.site-header__hamburger .icon {
	display: block;
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 1.375rem;
	height: 0.5625rem;
}
/* #endregion Site Header Hamburger button */

/* #region Styleguide */
.l-styleguide .site-header {
	position: relative;
}
/* #endregion Styleguide */
