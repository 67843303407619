.carousel {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
}

/* #region Buttons */
.carousel__previous-button,
.carousel__next-button {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	cursor: pointer;
	display: flex;
	flex-basis: 100%;
	outline: 0;
	padding: 0;
	justify-content: center;
	align-items: flex-end;
	position: relative;
	bottom: 0;
	height: 1.25rem;
	z-index: 2;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.carousel__previous-button,
	.carousel__next-button {
		height: 1.87rem;
	}
}

/* fix:  align-items on older webkit version does not work for some reason on these elements */
.carousel__previous-button > span,
.carousel__next-button > span {
	margin-top: 0.5625rem;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.carousel__previous-button > span,
	.carousel__next-button > span {
		margin-top: 1.1875rem;
	}
}

.carousel__previous-button:hover .icon {
	transform: translate3d(-0.3125rem, 0, 0);
}

.carousel__next-button:hover .icon {
	transform: translate3d(0.3125rem, 0, 0);
}

/* Arrows. */
.carousel__buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 9.375rem;
	height: 1.25rem;
	z-index: 2;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.carousel__buttons {
		height: 1.87rem;
	}
}

.carousel__previous-button span,
.carousel__next-button span {
	pointer-events: none;
}

.carousel__previous-button .icon,
.carousel__next-button .icon {
	display: block;
	transition: transform 96ms ease-out;
	width: 0.6875rem;
	height: 0.6875rem;
}

.carousel__counter {
	display: flex;
	flex-basis: 100%;
	line-height: 1;
	justify-content: center;
	align-self: flex-end;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.carousel__previous-button::before,
	.carousel__next-button::before {
		background-repeat: no-repeat;
		background-size: 0.875rem 1.5rem;
	}
}

/* States. */
.carousel__previous-button:focus-visible .icon {
	border: 1px solid var(--color-black);
}

.carousel__next-button:focus-visible .icon {
	border: 1px solid var(--color-black);
}
/* #endregion Buttons */

/* #region Items */
.carousel__items {
	aspect-ratio: 3 / 2;
	position: relative;
	width: 100%;
	z-index: 1;
}
/* #endregion Item */

/* #region Item */
.carousel__item {
	display: none;
	font-size: 0;
	margin: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 128ms linear;
	width: 100%;
	height: 100%;
}

.carousel__item[data-carousel-item-current],
.carousel__item[data-carousel-item-next],
.carousel__item[data-carousel-item-previous] {
	display: block;
}

.carousel__item[data-carousel-item-current] {
	opacity: 1;
	z-index: 2;
}

.carousel__item[data-carousel-item-next],
.carousel__item[data-carousel-item-previous] {
	z-index: 1;
}
/* #endregion Item */

/* #region Image */
.carousel__image {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.carousel__image {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
/* #endregion Image */
