/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.service-price-list {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.service-price-list {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 8)) + calc(calc(8 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.service-price-list {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 8)) + calc(calc(8 - 1) * var(--column-gap-from-large)));
	}
}

.service-price-list__title {
	color: var(--color-black);
}

.service-price-list__accordion {
}

/* #region Service price list accordion item */
.service-price-list__accordion__item {
	border-bottom: 1px solid var(--color-light-grey);
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.service-price-list__accordion__item:first-child {
	padding-top: 0;
}

.service-price-list__accordion__item[is-open] {
	border-bottom-color: var(--color-black);
}

.service-price-list__accordion__item[is-open] .service-price-list__accordion__data {
	color: var(--color-black);
}
/* #endregion Service price list accordion item */

/* #region Service price list accordion data */
.service-price-list__accordion__data {
	display: flex;
	flex-wrap: wrap;
	transition: color 96ms ease-out;
}

.service-price-list__accordion__data__title {
	box-sizing: border-box;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	padding-right: var(--space-24);
}

/* up-to-medium */
@media (max-width: 1023px) {
	.service-price-list__accordion__data__title {
		padding-bottom: 0.375rem;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.service-price-list__accordion__data__title {
		padding-right: var(--space-32);
	}
}

.service-price-list__accordion__data__time,
.service-price-list__accordion__data__price,
.service-price-list__accordion__data__toggle {
	flex-basis: 33.33%;
	flex-grow: 0;
	flex-shrink: 0;
}

.service-price-list__accordion__data__toggle {
	text-align: right;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.service-price-list__accordion__data__title {
		flex-basis: 51%;
	}

	.service-price-list__accordion__data__time {
		flex-basis: 20%;
	}

	.service-price-list__accordion__data__price {
		flex-basis: 16%;
	}

	.service-price-list__accordion__data__toggle {
		flex-basis: 13%;
	}
}
/* #endregion Service price list accordion data */

/* #region Service price list accordion trigger */
.service-price-list__accordion__trigger {
	appearance: none;
	background-color: transparent;
	border: none;
	color: var(--color-black);
	padding: 0;
	text-decoration: underline;
	transition: color 96ms linear;
}

html:not(.no-js, .js-loading-error) .service-price-list__accordion__trigger:is(:hover, :focus) {
	cursor: pointer;
}

html:not(.no-js, .js-loading-error) .service-price-list__accordion__trigger:hover {
	color: var(--color-green);
}

html:is(.no-js, .js-loading-error) .service-price-list__accordion__trigger {
	display: none;
}

.service-price-list__accordion__trigger span {
	pointer-events: none;
}

.service-price-list__accordion__trigger[aria-expanded="true"] .service-price-list__accordion__trigger__more,
.service-price-list__accordion__trigger[aria-expanded="false"] .service-price-list__accordion__trigger__less {
	display: none;
}
/* #endregion Service price list accordion trigger */

/* #region Service price list accordion panel */
html:not(.no-js, .js-loading-error) .service-price-list__accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .service-price-list__accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.service-price-list__accordion__panel__inner {
	padding-top: var(--space-24);
	padding-bottom: 0.5rem;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.service-price-list__accordion__panel__inner {
		width: 70%;
		width: clamp(50%, 41ch, 70%);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.service-price-list__accordion__panel__inner {
		padding-top: var(--space-32);
		padding-bottom: 1rem;
	}
}
/* #endregion Service price list accordion panel */
