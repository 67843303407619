* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 0.875rem;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	body {
		font-size: 1rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: var(--font-sans-with-serif-numbers);
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child,
p:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h1,
h2,
h3,
h4 {
	color: var(--color-black);
}

/**
 * Names used in Sketch design files:
 * - ParagraphXLarge/Mobile/Black
 * - ParagraphXLarge/Desktop
 */
.type-a {
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 1.375rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.27;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	.type-a {
		font-size: 2rem;
		line-height: 1.25;
	}
}
/**
 * Names used in Sketch design files:
 * - H1/Mobile
 * - H1/Desktop
 */
h1:not([class*="type-"]),
h2:not([class*="type-"]),
.type-b {
	font-family: var(--font-serif);
	font-size: 1.25rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.3;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	h1:not([class*="type-"]),
	h2:not([class*="type-"]),
	.type-b {
		font-size: 1.625rem;
		line-height: 1.38;
	}
}

/**
 * Names used in Sketch design files:
 * - H2/Mobile
 * - H2/Desktop
 */
h3:not([class*="type-"]),
.type-c {
	font-family: var(--font-serif);
	font-size: 1.125rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.33;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	h3:not([class*="type-"]),
	.type-c {
		font-size: 1.25rem;
		line-height: 1.3;
	}
}

/**
 * Names used in Sketch design files:
 * - H3/Mobile
 * - H3/Desktop
 */
h4:not([class*="type-"]),
.type-d {
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 1.125rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.33;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	h4:not([class*="type-"]),
	.type-d {
		font-size: 1.25rem;
		line-height: 1.3;
	}
}

/**
 * Names used in Sketch design files:
 * - Blockquote/Mobile
 * - Blockquote/Desktop
 * - ParagraphLarge/Default/Mobile
 * - ParagraphLarge/Light/Mobile
 * - ParagraphLarge/Default/Desktop
 * - ParagraphLarge/Light/Desktop
 */
.type-e,
blockquote {
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.38;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	.type-e,
	blockquote {
		font-size: 1.25rem;
		line-height: 1.5;
	}
}

/**
 * Names used in Sketch design files:
 * - H4/Mobile
 * - H4/Desktop
 */
h5:not([class*="type-"]),
.type-f {
	color: var(--color-green);
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.38;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	h5:not([class*="type-"]),
	.type-f {
		font-size: 1.125rem;
		line-height: 1.33;
	}
}

/**
 * Names used in Sketch design files:
 * - Paragraph/Mobile/Default
 * - Paragraph/Mobile/Error
 * - Paragraph/Mobile/Success
 * - ParagraphLink/Black/Mobile
 * - ParagraphLink/Light/Mobile
 * - Paragraph/Desktop/Default
 * - Paragraph/Desktop/Error
 * - Paragraph/Desktop/Success
 * - ParagraphLink/Black/Desktop
 * - ParagraphLink/Light/Desktop
 * - Sleeps-2-adults-14--Copy-2-Style
 */
p:not([class*="type-"]),
.type-g {
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	p:not([class*="type-"]),
	.type-g {
		font-size: 1rem;
	}
}

/**
 * Names used in Sketch design files:
 * - H5/Mobile
 * - H5/Desktop
 */
h6:not([class*="type-"]),
.type-h {
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 0.8125rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.03375rem;
	line-height: 1.23;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	h6:not([class*="type-"]),
	.type-h {
		font-size: 0.875rem;
		letter-spacing: 0.03625rem;
		line-height: 1.14;
	}
}

/**
 * Names used in Sketch design files:
 * - ParagraphSmall/Mobile/Default
 * - ParagraphSmall/Desktop/Default
 */
.type-i,
cite {
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	.type-i,
	cite {
		font-size: 0.875rem;
	}
}

/**
 * Names used in Sketch design files:
 * - H6/Mobile
 * - H6/Desktop
 */
.type-j {
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.03125rem;
	line-height: 1.17;
	text-transform: uppercase;
}

i,
em {
	font-style: italic;
}

blockquote {
	margin: 0;
	width: 60%;
	width: clamp(50%, 42ch, 100%);
}

blockquote p + cite {
	display: block;
	margin-top: -1.125rem;
}

code {
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
}

/* from-wide,desktop
 */
@media (min-width: 1280px) {
	code {
		font-size: 1rem;
	}
}
