/*
 * Form.
 */
.form {
	max-width: 568px;
}

/* Hide all for children except the success area */
/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.form[state="success"] > *:not([success-area]) {
	display: none;
}

/*
 * Row.
 */
.form__row {
	width: 100%;
}

.form__row:not(:last-child) {
	margin-bottom: var(--space-16);
}

*.form__row:last-of-type {
	margin-bottom: var(--space-32);
}

.form__row--disabled {
	opacity: 0.4;
}

/*
 * Label.
 */
.form__label-container {
	display: block;
	margin-bottom: 0.25rem;
	width: 100%;
}

.form__row--disabled .form__label {
	color: var(--color-charcoal-alpha-40);
	cursor: not-allowed;
}

.form__row--error .form__label {
	color: var(--color-error);
}

/*
 * Error.
 */
.form__error {
	color: var(--color-error);
	margin-top: 0.25rem;
}

/*
 * Fields.
 */
.form__input,
.form__select,
.form__textarea {
	background-color: transparent;
	border: 1px solid var(--color-light-grey);
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	outline: 0;
	padding: 0.75rem var(--space-16);
	transition: border-color 96ms linear;
	width: 100%;
}

@media (prefers-contrast: more) {
	.form__input,
	.form__select,
	.form__textarea {
		border-color: var(--color-charcoal);
	}
}

/* Fields — Select. */
.form__select-container {
	position: relative;
}

.form__select {
	appearance: none;
	color: var(--color-black);
	padding-right: var(--space-32);
}

.form__select__arrow {
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: var(--space-16);
	transform: translateY(-50%);
}

.form__select__arrow .icon {
	display: block;
	width: 0.875rem;
	height: 0.875rem;
}

/* Fields — Textarea. */
.form__textarea {
	height: 150px;
}

/* Fields — States. */
.form__input:focus,
.form__select:focus,
.form__textarea:focus {
	border-color: var(--color-black);
}

.form__input:disabled,
.form__select:disabled,
.form__textarea:disabled {
	cursor: not-allowed;
}

.form__row--error .form__input,
.form__row--error .form__select,
.form__row--error .form__textarea {
	border-color: var(--color-error);
}

/* Fields — Pseudo elements. */
.form__input::placeholder {
	color: var(--color-charcoal);
}

.form__select::placeholder {
	color: var(--color-charcoal);
}

.form__textarea::placeholder {
	color: var(--color-charcoal);
}

.form__select::-ms-expand {
	display: none;
}

/*
 * Checkboxes and radios.
 */
.form__checkbox:not(:last-child),
.form__radio:not(:last-child) {
	margin-bottom: 0.1875rem;
}

.form__checkbox__input,
.form__radio__input {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.form__checkbox__label,
.form__radio__label {
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	margin-left: var(--space-32);
	position: relative;
}

/* Checkboxes and radios — States. */
.form__checkbox__input:disabled + .form__checkbox__label,
.form__radio__input:disabled + .form__radio__label {
	cursor: not-allowed;
	opacity: 0.4;
}

/* Checkboxes and radios — Pseudo elements. */
.form__checkbox__label::before,
.form__radio__label::before,
.form__checkbox__label::after,
.form__radio__label::after {
	content: "";
	position: absolute;
}

.form__checkbox__label::before,
.form__radio__label::before {
	border: 1px solid var(--color-charcoal);
	top: 0.125rem;
	left: calc(var(--space-32) * -1);
	width: 0.9375rem;
	height: 0.9375rem;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.form__checkbox__label::before,
	.form__radio__label::before {
		top: 0.25rem;
	}
}

.form__checkbox__label::after,
.form__radio__label::after {
	background-color: var(--color-black);
	opacity: 0;
	top: 0.3125rem;
	left: calc(var(--space-32) * -1 + 0.1875rem);
	transition: opacity 96ms ease-out;
	width: 0.6875rem;
	height: 0.6875rem;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.form__checkbox__label::after,
	.form__radio__label::after {
		top: 0.4375rem;
	}
}

.form__radio__label::before,
.form__radio__label::after {
	border-radius: 50%;
}

/* Checkboxes and radios — Pseudo elements — States. */
.form__checkbox__input:disabled + .form__checkbox__label::before,
.form__radio__input:disabled + .form__radio__label::before {
	cursor: not-allowed;
}

.form__checkbox__input:focus + .form__checkbox__label,
.form__radio__input:focus + .form__radio__label {
	color: var(--color-black);
	text-decoration: underline;
}

.form__checkbox__input:checked + .form__checkbox__label::after,
.form__radio__input:checked + .form__radio__label::after {
	opacity: 1;
}

/* Date input */
.form__input--date::-webkit-calendar-picker-indicator {
	background: none;
	color: transparent;
}
