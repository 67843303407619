.teaser {
	position: relative;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.teaser {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

/* #region Teaser Image */
.teaser__image__source {
	width: 100%;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.teaser__image {
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 8)) + calc(calc(8 - 1) * var(--column-gap)));
		flex-grow: 0;
		flex-shrink: 0;
		align-self: flex-end;
	}

	.teaser--image-right .teaser__image {
		order: 2;
	}

	.teaser--image-right .teaser__summary {
		order: 1;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.teaser__image {
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 8)) + calc(calc(8 - 1) * var(--column-gap-from-large)));
	}

	.teaser--image-right .teaser__title {
		margin-left: auto;
	}
}

/* #endregion Teaser Image */

/* #region Teaser Summary */
.teaser__summary {
	margin-top: 1.125rem;
}

.teaser__summary__title {
	margin-bottom: 1.125rem;
}

.teaser__summary p {
	margin-top: var(--space-16);
	margin-bottom: var(--space-16);
}

.teaser__summary p:first-of-type {
	margin-top: 0;
}

.teaser__summary p:last-of-type {
	margin-bottom: 0;
}

.teaser__summary .arrow-link {
	margin-top: 0.4375rem;
}

.teaser__summary p .arrow-link:first-child {
	margin-top: 0;
}

.teaser__summary__cta {
	margin-top: 1.125rem;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.teaser__summary {
		border-left: 1px solid var(--color-light-grey);
		box-sizing: border-box;
		flex-basis: calc(((100% - (11 * var(--column-gap))) / (12 / 4)) + ((4 - 1) * var(--column-gap)));
		flex-grow: 0;
		flex-shrink: 0;
		padding-left: var(--space-24);
		align-self: flex-end;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.teaser__summary {
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 4)) + calc(calc(4 - 1) * var(--column-gap-from-large)));
	}

	.teaser__summary__title {
		/* hide title in summary on large screens */
		display: none;
	}

	.teaser__summary__cta {
		margin-top: 1.625rem;
	}
}
/* #region Teaser Summary */

/* #region Summary Title */
.teaser__title {
	display: none;
	order: 3;
}

/* from-large */
@media (min-width: 1440px) {
	.teaser__title {
		display: block;
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 8)) + calc(calc(8 - 1) * var(--column-gap-from-large)));
		flex-grow: 0;
		flex-shrink: 0;
		margin-top: 1.125rem;
	}

	.teaser__title h3 {
		width: 70%;
		width: clamp(60%, 50ch, 70%);
	}
}
/* #endregion Summary Title */
