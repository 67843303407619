.green-overlay {
}

.green-overlay__header {
	box-sizing: border-box;
	padding-top: 0.938rem;
	position: sticky;
	text-align: right;
	top: 0;
	left: 0;
	width: 100%;
	height: 3.125rem;
	z-index: 2;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.green-overlay__header {
		padding-top: 2.25rem;
		height: 6.25rem;
	}
}

.green-overlay__close-button {
	appearance: none;
	background-color: transparent;
	border: none;
	color: var(--color-black);
	cursor: pointer;
	padding: 0;
	text-transform: uppercase;
	transition: color 96ms linear;
}

.green-overlay__close-button:hover {
	color: var(--color-off-white);
}

.green-overlay__close-button__span {
	font-size: 0.75rem;
}

.green-overlay__content {
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	padding-top: var(--space-40);
	scroll-behavior: smooth;
	width: 100%;
	z-index: 1;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.green-overlay__content {
		box-sizing: border-box;
		padding-top: 0;
		position: absolute;
		top: 6.25rem;
		left: calc((100% - 972px) / 2);
		width: 972px;
		max-height: calc(100% - 6.25rem - 2.8125rem);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.green-overlay__content {
		left: calc((100% - 1320px) / 2);
		width: 1320px;
	}
}
