.room-detail {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.room-detail {
		box-sizing: border-box;
		display: grid;
		grid-gap: 3rem;
		grid-template-areas: "text image";
		grid-template-columns: 1fr 1fr;
		padding-top: var(--space-48);
		padding-right: var(--space-48);
		padding-bottom: var(--space-48);
		padding-left: var(--space-48);
		width: 100%;
	}
}

.room-detail__text {
	grid-area: text;
}

.room-detail__text__content-block {
	padding-top: var(--space-40);
	padding-bottom: var(--space-40);
}

.room-detail__text__content-block:first-child {
	padding-top: 0;
}

.room-detail__text__content-block:last-child {
	padding-bottom: 0;
}

.room-detail__text__description-list {
	line-height: 1.5rem;
	list-style: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.room-detail__text__description-list {
		columns: 2;
	}
}

.room-detail__text__description-list__item::before {
	content: "–\0000a0\0000a0"; /* \0000a0 is a non-breaking space */
}

.room-detail__media {
	grid-area: image;
	width: 100%;
}

.room-detail__media__aspect-ratio-container {
	aspect-ratio: 2 / 3;
	display: flex;
}

.room-detail__media__image {
	padding-top: var(--space-40);
	width: 100%;
	height: auto;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.room-detail__media__image {
		padding-top: 0;
	}
}

@supports (object-fit: cover) {
	.room-detail__media__image {
		object-fit: cover;
		height: 100%;
	}
}
