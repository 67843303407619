.photo-album {
}

.photo-album__row:not(:last-child) {
	margin-bottom: var(--space-24);
}

@media (min-width: 768px) {
	.photo-album__row:not(:last-child) {
		margin-bottom: var(--space-40);
	}
}

@media (min-width: 1024px) {
	.photo-album__row {
		display: grid;
		grid-row-gap: 0;
		grid-column-gap: 48px; /* IE fix */
		grid-template-areas: "image-a image-b image-c" "caption-a caption-b caption-c";
		grid-template-rows: 1fr max-content;
		grid-template-columns: repeat(3, 1fr);
	}

	@supports (grid-template-rows: 1fr max-content) {
		.photo-album__row {
			grid-template-rows: 1fr max-content;
		}
	}
}

.photo-album__image {
}

/* up-to-small */
@media (max-width: 767px) {
	.photo-album__image:not(:first-child) {
		margin-top: var(--space-24);
	}
}

/* up-to-medium */
@media (max-width: 1023px) {
	.photo-album__image:not(:first-child) {
		margin-top: var(--space-40);
	}
}

@media (min-width: 1024px) {
	.photo-album__image {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.photo-album__image--1 {
		grid-area: image-a;
	}

	.photo-album__image--2 {
		grid-area: image-b;
	}

	.photo-album__image--3 {
		grid-area: image-c;
	}

	.photo-album__image-caption--1 {
		grid-area: caption-a;
	}

	.photo-album__image-caption--2 {
		grid-area: caption-b;
	}

	.photo-album__image-caption--3 {
		grid-area: caption-c;
	}
}

.photo-album__image__media {
	flex-shrink: 0; /* IE11 */
	width: 100%;
	height: auto;
}

.photo-album__image-caption {
	width: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 9)) + calc(calc(9 - 1) * var(--column-gap)));
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.photo-album__image-caption {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap)));
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.photo-album__image-caption {
		width: calc(calc(calc(100% - calc(4 * var(--column-gap))) / calc(4 / 3)) + calc(calc(4 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.photo-album__image-caption {
		width: calc(calc(calc(100% - calc(4 * var(--column-gap-from-large))) / calc(4 / 3)) + calc(calc(4 - 1) * var(--column-gap-from-large)));
	}
}

.photo-album__image-caption p {
	margin-top: var(--space-16);
	margin-bottom: 0;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.photo-album__image-caption p {
		margin-top: var(--space-24);
	}
}
