.social-links {
	list-style: none;
	margin: var(--space-32) 0 0;
	padding: 0;
}

.social-links__item {
	display: inline-block;
}

.social-links__item:not(:last-child) {
	margin-right: 1rem;
}

.social-links__link {
	transition: color 96ms linear;
}

.social-links__link:hover {
	color: var(--color-green);
}

.social-links__link .icon {
	fill: currentColor;
	width: 1.25rem;
	height: 1.25rem;
}

.theme-green-black .social-links__link {
	color: var(--color-black);
}

.theme-green-black .social-links__link:hover {
	color: var(--color-off-white);
}
