.modal-dialog[data-state="opening"] .video-overlay,
.modal-dialog[data-state="open"] .video-overlay,
.modal-dialog[data-state="closing"] .video-overlay {
	background-color: var(--color-green-alpha-90);
	display: block;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-modal-dialog);
}

.video-overlay__close-button {
	-mrh-resets: button;
	color: var(--color-white);
	display: block;
	font-size: 0.75rem;
	line-height: 1.5;
	position: absolute;
	text-transform: uppercase;
	top: var(--space-16);
	right: var(--space-16);
	transition: color 96ms linear;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.video-overlay__close-button {
		top: 40px;
		right: 40px;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.video-overlay__close-button {
		right: 48px;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.video-overlay__close-button {
		right: 80px;
	}
}

.video-overlay__close-button:hover {
	color: var(--color-black);
}

/* #region Media */
.video-overlay__media {
	position: relative;
	width: 100%;
	height: 100%;
}

.video-overlay__media__player-container {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-inline: 16px;
	padding-block: 4.375rem;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;
	max-height: 100%;
}

.video-overlay__media__player {
	aspect-ratio: 16 / 9;
	display: block;
	margin: 0 auto;
	position: relative;
	max-width: 100%;
	max-height: 100%;
	z-index: 2;
}

@supports (aspect-ratio: 1 / 1) {
	.video-overlay__media__player {
		--aspect-ratio: 16 / 9;

		aspect-ratio: var(--aspect-ratio);
	}
}

.video-overlay__media__source {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.video-overlay__background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}
/* #endregion Media */
