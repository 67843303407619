.doormat {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.doormat {
		margin: 0 auto;
		width: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 4)) + calc(calc(4 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.doormat {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 4)) + calc(calc(4 - 1) * var(--column-gap-from-large)));
	}
}

.doormat__text {
	margin-top: 0;
	margin-bottom: 0.6875rem;
}

/* from-large */
@media (min-width: 1440px) {
	.doormat__text {
		margin-bottom: var(--space-24);
	}
}
