.popup {
	background-color: var(--color-green-alpha-90);
	box-sizing: border-box;
	content-visibility: hidden;
	display: none;
	opacity: 0;
	outline: none;
	overflow-y: auto;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 0;
	z-index: var(--z-index-modal-dialog);
}

.l-styleguide ~ .popup {
	--z-index-modal-dialog: 9999999;
}

.popup[data-state="opening"],
.popup[data-state="open"],
.popup[data-state="closing"] {
	content-visibility: visible;
	display: block;
	opacity: 1;
	justify-content: center;
	align-items: center;
	pointer-events: auto;
}

.popup__inner-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
}

.popup__inner {
	box-sizing: border-box;
	opacity: 0;
	padding-inline: var(--space-16);
	padding-block-start: 2.875rem;
	padding-block-end: var(--space-16);
	position: relative;
	transform: scale(1.1);
	width: 100%;
	max-width: 33.25rem;
}

.popup__inner__close-button {
	-mrh-resets: button;
	color: var(--color-white);
	font-size: 0.75rem;
	line-height: 1.5;
	position: absolute;
	text-transform: uppercase;
	top: var(--space-16);
	right: var(--space-16);
	transition: color 96ms linear;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.popup__inner__close-button {
		font-size: 0.875rem;
	}
}

.popup__inner__close-button:hover {
	color: var(--color-black);
}

.popup__content:focus-visible {
	/* First focusable modal dialog child */
	outline: 0;
}

.popup__content__text,
.popup__content__link {
	padding-inline: var(--space-24);
	padding-block: var(--space-24);
}

.popup__content__image {
	display: block;
	width: 100%;
}

.popup__content__link {
	text-align: center;
}

.popup__content__text + .popup__content__link {
	padding-top: 0;
}

.popup__background {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
