.info-block {
}

/* #region Accordion */
.info-block__accordion {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.info-block__accordion {
		margin-left: auto;
		width: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.info-block__accordion {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap-from-large)));
	}
}
/* #endregion Accordion */

/* #region Media */
.info-block__media {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.info-block__media {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 9)) + calc(calc(9 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.info-block__media {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 9)) + calc(calc(9 - 1) * var(--column-gap-from-large)));
	}
}

.info-block__media__image {
	width: 100%;
}
/* #endregion Media */
