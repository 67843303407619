.room-teaser {
}

/* #region layout */
.room-teaser__grid {
	display: grid;
	grid-template-areas:
		"area-a"
		"area-b"
		"area-c"
		"area-d";
	grid-template-rows: min-content min-content min-content min-content;
	grid-template-columns: 1fr;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.room-teaser__grid {
		display: grid;
		grid-template-areas:
			"area-a area-a"
			"area-b area-c"
			"area-d area-e";
		grid-template-rows: min-content min-content min-content;
		grid-template-columns: 1fr 1fr;
	}
}

.room-teaser__grid__title-area {
	grid-area: area-a;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.room-teaser__grid__title-area {
		grid-area: area-b;
	}
}

.room-teaser__grid__subtitle-area {
	grid-area: area-b;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.room-teaser__grid__subtitle-area {
		grid-area: area-c;
	}
}

.room-teaser__grid__carousel-area {
	grid-area: area-c;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.room-teaser__grid__carousel-area {
		grid-area: area-a;
	}
}

.room-teaser__grid__content-area {
	grid-area: area-d;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.room-teaser__grid__content-area {
		grid-area: area-e;
	}
}
/* #endregion layout */

.room-teaser__title {
	color: var(--color-black);
}

.room-teaser__title,
.room-teaser__subtitle {
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: var(--space-24);
}

/* from-large */
@media (min-width: 1440px) {
	.room-teaser__subtitle {
		padding-bottom: var(--space-32);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	@supports (-webkit-line-clamp: 2) {
		.room-teaser__subtitle p {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			display: -webkit-box;
			overflow: hidden;
			min-height: 3em; /* 2 lines */
		}
	}
}

.room-teaser__carousel {
	margin-bottom: var(--space-24);
}

/* from-large */
@media (min-width: 1440px) {
	.room-teaser__carousel {
		margin-bottom: var(--space-40);
	}
}

.room-teaser__content__description {
	margin-top: 0;
	margin-bottom: var(--space-16);
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.room-teaser__content__description {
		margin-bottom: var(--space-24);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.room-teaser__content__description {
		margin-bottom: var(--space-16);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	@supports (-webkit-line-clamp: 4) {
		.room-teaser__content__description p {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			display: -webkit-box;
			overflow: hidden;
			min-height: 6em; /* 4 lines */
		}
	}
}

.room-teaser__content__read-more {
	margin-bottom: var(--space-24);
}

/* from-large */
@media (min-width: 1440px) {
	.room-teaser__content__read-more {
		margin-bottom: var(--space-40);
	}
}
