.accordion {
	display: block;
	width: 100%;
}

/* #region Wrapper */
.accordion-wrapper {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.accordion-wrapper {
		margin-left: auto;
		width: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.accordion-wrapper {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap-from-large)));
	}
}
/* #endregion Wrapper */

/* #region Item */
.accordion__item {
	border-bottom: 1px solid var(--color-light-grey);
}

.accordion__item:first-child {
	border-top: 1px solid var(--color-light-grey);
}
/* #endregion Item */

/* #region Heading */
.accordion__heading {
	margin: 0;
	padding: 0;
}
/* #endregion Heading */

/* #region Trigger */
.accordion__trigger {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-sizing: border-box;
	color: var(--color-black);
	cursor: default;
	display: flex;
	padding: 1.1875rem 0;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.accordion__trigger {
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
	}
}

.accordion__trigger:is(:hover, :focus) {
	cursor: pointer;
}

.accordion__trigger:hover .accordion__trigger__title {
	color: var(--color-charcoal);
}

.accordion__trigger span {
	pointer-events: none;
}

.accordion__trigger__title {
	margin-right: var(--space-24);
	position: relative;
	text-align: left;
	transition: color 96ms linear;
}

.accordion__trigger__icon {
	flex-grow: 0;
	flex-shrink: 0;
	transition: transform 320ms ease-in-out;
	width: 0.75rem;
	height: 0.75rem;
}

.accordion__trigger[aria-expanded="true"] .accordion__trigger__icon {
	transform: rotate(180deg);
}

.accordion__trigger[aria-expanded="false"]:hover .accordion__trigger__icon {
	transform: translate3d(0, 0.3125rem, 0) rotate(0);
}

.accordion__trigger[aria-expanded="true"]:hover .accordion__trigger__icon {
	transform: translate3d(0, -0.3125rem, 0) rotate(180deg);
}

.accordion__trigger__icon .icon {
	display: block;
	fill: currentColor;
	width: 100%;
	height: 100%;
}

.accordion__trigger[aria-expanded="true"] .accordion__trigger__icon {
	transform: rotate(180deg);
}
/* #endregion Trigger */

/* #region Panel */
.accordion__panel {
	color: var(--color-charcoal);
	padding-right: var(--space-16);
	padding-bottom: var(--space-24);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.accordion__panel {
		padding-bottom: var(--space-32);
	}
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.accordion__panel__inner {
		width: calc(calc(calc(100% - calc(6 * var(--column-gap))) / calc(6 / 4)) + calc(calc(4 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.accordion__panel__inner {
		width: calc(calc(calc(100% - calc(6 * var(--column-gap-from-large))) / calc(6 / 4)) + calc(calc(4 - 1) * var(--column-gap-from-large)));
	}
}

.accordion__panel[hidden] {
	display: none;
}

@media (prefers-reduced-motion: no-preference) {
	.accordion__panel[hidden] {
		animation: ACCORDION_FADE_IN 384ms ease-in forwards 1;
		opacity: 0;
	}
}

@supports (content-visibility: hidden) {
	.accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}
/* #endregion Panel */

/* #region Keyframe animations */
@keyframes ACCORDION_FADE_IN {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
/* #endregion Keyframe animations */
