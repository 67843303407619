.rooms-list {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.rooms-list {
		display: grid;
		grid-column-gap: var(--space-48);
		grid-template-columns: 1fr 1fr;
		/* see .wrapper */
		padding-right: calc((100% - 972px) / 2);
		padding-left: calc((100% - 972px) / 2);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.rooms-list {
		/* see .wrapper */
		padding-right: calc((100% - 1320px) / 2);
		padding-left: calc((100% - 1320px) / 2);
	}
}

/* up-to-medium */
@media (max-width: 1023px) {
	.rooms-list__item-wrapper:nth-child(n + 2) {
		padding-top: var(--space-40);
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.rooms-list__item-wrapper:nth-child(n + 3) {
		padding-top: var(--space-40);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.rooms-list__item-wrapper:nth-child(n + 3) {
		padding-top: var(--space-80);
	}
}

.rooms-list__separator {
	margin-top: var(--space-40);
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.rooms-list__separator {
		display: none;
	}
}
