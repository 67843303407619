@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	text-underline-position: from-font;
	min-height: 100vh;
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	background-color: var(--color-light-grey);
	border: none;
	margin: 0;
	height: 1px;
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

table,
th,
td {
	border: 1px solid var(--color-light-grey);
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	color: var(--color-charcoal);
	margin: var(--space-24) 0;
	width: 100%;
	max-width: 100%;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	table {
		margin-top: var(--space-32);
		margin-bottom: var(--space-32);
	}
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th {
	color: var(--color-black);
	font-weight: 400;
}

th,
td {
	box-sizing: border-box;
	padding: var(--space-16);
	text-align: left;
}

ol:not([class]),
ul:not([class]) {
	box-sizing: border-box;
	line-height: 1.5;
	margin: 0 0 var(--space-24) 0;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	ol:not([class]),
	ul:not([class]) {
		margin: 0 0 var(--space-32) 0;
	}
}

ol:not([class]) ul:not([class]),
ul:not([class]) ul:not([class]) {
	padding-left: 0;
}

ul:not([class]) ol:not([class]) {
	padding-left: 1rem;
}

ol:not([class]):last-child,
ul:not([class]):last-child {
	margin-bottom: 0;
}

li ol:not([class]),
li ul:not([class]) {
	padding-top: var(--space-8);
	padding-bottom: var(--space-8);
}

ul:not([class]) {
	list-style: none;
	padding-left: 0;
}

ol:not([class]) {
	padding-left: 1rem;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	ol:not([class]) {
		padding-left: 1rem;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	ol:not([class]) > li:not([class]) {
		padding-left: 0.5rem;
	}
}

li:not([class]) {
	margin: 0;
	padding: 0;
	position: relative;
}

ul > li:not([class]) {
	padding-left: var(--space-16);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	ul > li:not([class]) {
		padding-left: var(--space-24);
	}
}

ul:not([class]) > li:not([class])::before {
	content: "•";
	position: absolute;
	left: 0;
}

a:not([class]) {
	color: var(--color-black);
	display: inline;
	text-decoration: underline;
}

a:not([class]):active,
a:not([class]):focus,
a:not([class]):hover {
	text-decoration: none;
}

p a:not([class]) {
	line-height: inherit;
}
