.site-footer {
}

/* #region Site Footer Row */
.site-footer__row {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-footer__row {
		display: flex;
		justify-content: space-between;
	}
}
/* #endregion Site Footer Row */

/* #region Site Footer Column */
.site-footer__column {
}

/* up-to-medium */
@media (max-width: 1023px) {
	.site-footer__column:not(:last-child) {
		margin-bottom: 3.5rem;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-footer__column {
		box-sizing: border-box;
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 4)) + calc(calc(4 - 1) * var(--column-gap)));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.site-footer__column {
		flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 4)) + calc(calc(4 - 1) * var(--column-gap-from-large)));
	}
}

.site-footer__column a {
	color: var(--color-black);
	text-decoration: none;
}

.site-footer__column a:hover {
	text-decoration: underline;
}

.site-footer__column a.arrow-link {
	margin-top: var(--space-8);
}
/* #endregion Site Footer Column */

/* #region Site Footer Explore more links */
.site-footer__explore-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.site-footer__explore-list__item:not(:last-child) {
	margin-bottom: 0.4375rem;
}
/* #endregion Site Footer Explore more links */

/* #region Site Footer Final Row */
.site-footer__final-row {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-footer__final-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.site-footer__final-row {
		column-gap: 38px;
	}
}

.site-footer__final-row__column-a {
	flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 8)) + calc(calc(8 - 1) * var(--column-gap)));
	flex-grow: 0;
	flex-shrink: 0;
}

/* from-large */
@media (min-width: 1440px) {
	.site-footer__final-row__column-a {
		flex-basis: calc(calc(calc(100% - calc(11 * 38px)) / calc(12 / 8)) + calc(calc(8 - 1) * 38px));
	}
}

.site-footer__final-row__column-b {
	display: flex;
	flex-basis: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 4)) + calc(calc(4 - 1) * var(--column-gap)));
	flex-grow: 0;
	flex-shrink: 0;
	flex-direction: column;
	row-gap: 2rem;
	padding-top: var(--space-40);
	align-items: flex-start;
}

/* from-xsmall */
@media (min-width: 568px) {
	.site-footer__final-row__column-b {
		flex-direction: row;
		column-gap: 48px;
		align-items: center;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.site-footer__final-row__column-b {
		padding-top: 0;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.site-footer__final-row__column-b {
		flex-basis: calc(calc(calc(100% - calc(11 * 38px)) / calc(12 / 4)) + calc(calc(4 - 1) * 38px));
	}
}

/* #endregion Site Footer Final Row */

/* #region Site Footer Menu */
.site-footer__menu {
	margin-bottom: calc(-1 * var(--space-16));
}

.site-footer__menu__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.site-footer__menu__list-item {
	display: inline-block;
	margin-bottom: var(--space-16);
}

.site-footer__menu__list-item:not(:last-child) .site-footer__menu__link {
	margin-right: 2.125rem;
}

/* from-large */
@media (min-width: 1440px) {
	.site-footer__menu__list-item:not(:last-child) .site-footer__menu__link {
		margin-right: 2.25rem;
	}
}

.site-footer__menu__list-item--current {
	text-decoration: underline;
}

.site-footer__menu__link {
	color: var(--color-charcoal);
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.03125rem;
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 96ms linear;
}

.site-footer__menu__link:hover {
	color: var(--color-black);
	text-decoration: underline;
}

.site-footer__partner-link {
	display: block;
}

/* from-large */
@media (min-width: 1440px) {
	#site-footer__partner-link__icon__lifestylehotels {
		margin-top: -33px;
	}
}
/* #endregion Site Footer Menu */
