.arrow-link {
	color: var(--color-black);
	display: inline-block;
	position: relative;
	text-decoration: none;
	transition: color 96ms ease-out;
}

button.arrow-link {
	appearance: none;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
	text-align: left;
}

.arrow-link:hover {
	color: var(--color-green);
	text-decoration: underline;
}

.arrow-link .icon {
	fill: currentColor;
	padding-left: 0.5rem;
	transform: translate3d(0, -0.0625rem, 0);
	transition:
		color 96ms ease-out,
		transform 96ms ease-out;
	vertical-align: middle;
	width: 0.75rem;
	height: 0.75rem;
}

.arrow-link:hover .icon {
	color: inherit;
	transform: translate3d(0.3125rem, -0.0625rem, 0);
}

.theme-green-black .arrow-link:hover {
	color: var(--color-black);
}

.theme-green-white .arrow-link:hover {
	color: var(--color-white);
}

.theme-transparent-white .arrow-link:hover {
	color: var(--color-white);
}
