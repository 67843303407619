.notification-bar {
	display: block;
	text-decoration: none;
	width: 100%;
	height: 2.5rem;
}

.notification-bar__content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
}

.l-styleguide .notification-bar {
	position: relative;
}

/* Region modifiers for marquee */
.notification-bar--no-marquee {
	display: flex;
	justify-content: center;
	align-items: center;
}

.notification-bar__text {
	opacity: 0;
	transition: opacity 512ms ease-out;
}

.notification-bar__text,
.notification-bar__text p {
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
}

.notification-bar__text--no-marquee {
	margin-top: 0;
}
/* endregion modifiers for marquee */
