.booking-navigation {
}

.booking-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.booking-navigation__list__item:not(:last-child) {
	margin-bottom: 0.375rem;
}

.booking-navigation__link {
	color: inherit;
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 1.375rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.27;
	text-decoration: none;
	transition: color 96ms linear;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.booking-navigation__link {
		font-size: 0.875rem;
		font-stretch: normal;
		font-style: normal;
		font-weight: 400;
		letter-spacing: normal;
		line-height: 1.5;
	}
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.booking-navigation__link {
		font-size: 1rem;
	}
}

.booking-navigation__link:hover,
.booking-navigation__list__item--current .booking-navigation__link {
	color: black;
	text-decoration: underline;
}
