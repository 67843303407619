.button {
	appearance: none;
	background-color: var(--color-green);
	border: none;
	color: var(--color-off-white);
	cursor: pointer;
	display: inline-block;
	font-family: var(--font-sans-with-serif-numbers);
	font-size: 0.875rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
	padding: 0.59375rem 1.25rem;
	text-align: center;
	text-decoration: none;
	transition: background-color 96ms linear;
}

.button:focus {
	background-color: var(--color-black);
}

.button:hover {
	background-color: var(--color-green-alpha-80);
}

.button--black {
	background-color: var(--color-black);
}

.button--black:hover {
	background-color: var(--color-black-alpha-80);
}

.button:disabled {
	background-color: var(--color-black-alpha-40);
	cursor: not-allowed;
}
