:root {
	/* #region Colors */
	--color-green: #51664d;
	--color-black: #000000;
	--color-off-white: #fcfcfa;
	--color-light-grey: #e0e0e0;
	--color-charcoal: #4b4e4b;
	--color-charcoal-alpha-40: rgb(75 78 75 / 0.4);
	--color-white: #ffffff;
	--color-error: #ab604d;
	--color-light-green: #ccd8cc;
	/*  */
	--color-foreground: #4b4e4b;
	--color-background: #fcfcfa;
	--color-green-background-for-text: #51664d;

	/* Transparent color names. */
	--color-black-alpha-40: rgb(0 0 0 / 0.4);
	--color-black-alpha-50: rgb(0 0 0 / 0.5);
	--color-black-alpha-80: rgb(0 0 0 / 0.8);
	--color-green-alpha-80: rgb(81 102 77 / 0.8);
	--color-green-alpha-90: rgba(81 102 77 / 0.9);
	--color-off-white-alpha-95: rgb(252 252 250 / 0.95);

	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "Euclid", "Helvetica Neue", "Arial", sans-serif;
	--font-sans-with-serif-numbers: "Euclid-With-Gza-Numbers", sans-serif;
	--font-serif: "Gza", serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-8: 0.5rem;
	--space-16: 1rem;
	--space-24: 1.5rem;
	--space-32: 2rem;
	--space-40: 2.5rem;
	--space-48: 3rem;
	--space-80: 5rem;
	/* #endregion Spacers */

	/* #region Rows */
	--row-spacing: 2.5rem; /* is --space-40 */
	--row-spacing-from-wide: 5rem; /* is --space-80 */
	/* #endregion Rows */

	/* #region Columns */
	--column-gap: 1.5rem; /* is --space-24 */
	--column-gap-from-large: 3rem; /* is --space-48 */
	/* #endregion Columns */

	/* #region Z-indices */
	--z-index-site-header: 101;
	--z-index-modal-dialog: 102;
	/* #endregion Z-indices */
}

@media (prefers-contrast: more) {
	:root {
		/* #region Colors */
		--color-green-background-for-text: #ccd8cc; /* --color-light-green */
		/* #endregion Colors */
	}
}
