.row:not([class*="theme-"]) {
	margin-top: var(--row-spacing);
	margin-bottom: var(--row-spacing);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.row:not([class*="theme-"]) {
		margin-top: var(--row-spacing-from-wide);
		margin-bottom: var(--row-spacing-from-wide);
	}
}

.row.theme-white,
.row.theme-off-white,
.row.theme-green-white,
.row.theme-green-black {
	padding-top: var(--row-spacing);
	padding-bottom: var(--row-spacing);
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.row.theme-white,
	.row.theme-off-white,
	.row.theme-green-white,
	.row.theme-green-black {
		padding-top: var(--row-spacing-from-wide);
		padding-bottom: var(--row-spacing-from-wide);
	}
}

.row.theme-off-white + .row.theme-off-white,
.row.theme-white + .row.theme-white,
.row.theme-green-white + .row.theme-green-white,
.row.theme-green-black + .row.theme-green-black {
	padding-top: 0;
}

/* #region modifier for booking layout */

/* from-wide,desktop */
@media (min-width: 1280px) {
	.row.row--booking {
		margin-top: var(--row-spacing);
		margin-bottom: var(--row-spacing);
	}
}

.row.row--booking:first-child {
	margin-top: 0;
}

.row.row--booking:last-child {
	margin-bottom: 0;
}
/* #endregion row modifier for booking layout */
