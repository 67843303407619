.wrapper {
	box-sizing: border-box;
	padding-right: var(--space-16);
	padding-left: var(--space-16);
	width: 100%;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.wrapper {
		padding-right: 3.75rem;
		padding-left: 3.75rem;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.wrapper {
		padding-right: calc((100% - 972px) / 2);
		padding-left: calc((100% - 972px) / 2);
	}
}

/* from-large */
@media (min-width: 1440px) {
	.wrapper {
		padding-right: calc((100% - 1320px) / 2);
		padding-left: calc((100% - 1320px) / 2);
	}
}
