.navigation-overlay {
}

.navigation-overlay--fallback {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* #region navigation overlay header */
.navigation-overlay__header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
}

.navigation-overlay__header__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 2.9375rem;
}

.navigation-overlay__logo {
	padding-top: 3px;
	width: 5.3125rem;
	height: 1.5625rem;
}

.navigation-overlay__close-button {
	appearance: none;
	background-color: transparent;
	border: none;
	color: var(--color-black);
	cursor: pointer;
	text-transform: uppercase;
	transition: color 96ms linear;
}

.navigation-overlay__close-button:hover {
	color: var(--color-off-white);
}

.navigation-overlay__close-button__span {
	font-size: 0.75rem;
}
/* #endregion navigation overlay header */

.navigation-overlay hr {
	background-color: var(--color-black);
}

.navigation-overlay nav[data-forced-focus] {
	outline: 0;
}

/* #region navigation overlay body */
.navigation-overlay__body {
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	padding-top: 2.3125rem;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.navigation-overlay__menu-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__menu-list--primary {
	font-size: 1.375rem;
}

.navigation-overlay__menu-list__item:not(:last-child) {
	margin-bottom: 0.5625rem;
}

.navigation-overlay__menu-list__link {
	color: var(--color-black);
	text-decoration: none;
	transition: color 96ms linear;
}

.navigation-overlay__menu-list__link:hover,
.navigation-overlay__menu-list__item--current,
.navigation-overlay__menu-list__item--current .navigation-overlay__menu-list__link {
	color: var(--color-off-white);
}
/* #endregion navigation overlay body */
