@font-face {
	font-family: "Euclid";
	src: url("../fonts/common/EuclidCircularA-Regular-WebXL.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0000-007F, U+00A0, U+00A9, U+00AD, U+00B0, U+00D7, U+00E0-00E1, U+00E3-00EB, U+00ED-00EF, U+00F2-00F6, U+00F8-00FC, U+0101, U+0113, U+0117, U+0119, U+012B, U+0301, U+0327, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Euclid";
	src: url("../fonts/extended/EuclidCircularA-Regular-WebXL.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0080-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00D6, U+00D8-00DF, U+00E2-00E2, U+00EC-00EC, U+00F0-00F1, U+00F7-00F7, U+00FD-0100, U+0102-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0300, U+0302-0326, U+0328-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Gza";
	src: url("../fonts/common/GzaRegular-Web.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0000-007F, U+00A0, U+00A9, U+00AD, U+00B0, U+00D7, U+00E0-00E1, U+00E3-00EB, U+00ED-00EF, U+00F2-00F6, U+00F8-00FC, U+0101, U+0113, U+0117, U+0119, U+012B, U+0301, U+0327, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Gza";
	src: url("../fonts/extended/GzaRegular-Web.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0080-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00D6, U+00D8-00DF, U+00E2-00E2, U+00EC-00EC, U+00F0-00F1, U+00F7-00F7, U+00FD-0100, U+0102-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0300, U+0302-0326, U+0328-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Euclid-With-Gza-Numbers";
	src: url("../fonts/common/EuclidCircularA-Regular-WebXL.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0000-007F, U+00A0, U+00A9, U+00AD, U+00B0, U+00D7, U+00E0-00E1, U+00E3-00EB, U+00ED-00EF, U+00F2-00F6, U+00F8-00FC, U+0101, U+0113, U+0117, U+0119, U+012B, U+0301, U+0327, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+20AC, U+2191, U+2193, U+21A9, U+21D2, U+23E4, U+25A0, U+25AA, U+26A1, U+30FB;
}

@font-face {
	font-family: "Euclid-With-Gza-Numbers";
	src: url("../fonts/extended/EuclidCircularA-Regular-WebXL.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
	unicode-range: U+0080-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00D6, U+00D8-00DF, U+00E2-00E2, U+00EC-00EC, U+00F0-00F1, U+00F7-00F7, U+00FD-0100, U+0102-0112, U+0114-0116, U+0118-0118, U+011A-012A, U+012C-0300, U+0302-0326, U+0328-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-20AB, U+20AD-2190, U+2192-2192, U+2194-21A8, U+21AA-21D1, U+21D3-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-26A0, U+30FC-FFFFF;
}

@font-face {
	font-family: "Euclid-With-Gza-Numbers";
	src: url("../fonts/common/GzaRegular-Web.woff2") format("woff2");
	unicode-range: U+30-39;
}
