.wysiwyg {
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.wysiwyg {
		width: calc(calc(calc(100% - calc(7 * var(--column-gap))) / calc(8 / 6)) + calc(calc(6 - 1) * var(--column-gap)));
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.wysiwyg {
		width: calc(calc(calc(100% - calc(7 * var(--column-gap))) / calc(8 / 5)) + calc(calc(5 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.wysiwyg {
		width: calc(calc(calc(100% - calc(7 * var(--column-gap-from-large))) / calc(8 / 5)) + calc(calc(5 - 1) * var(--column-gap-from-large)));
	}
}
