.l-site {
}

.l-site.has-notification-bar {
	padding-top: 2.5rem;
}

.l-site__header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: var(--z-index-site-header);
}

.l-site__content {
}

.l-site__content.without-hero {
	margin-top: 3rem;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-site__content.without-hero {
		margin-top: 5.0625rem;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.l-site__content.without-hero {
		margin-top: 6.0625rem;
	}
}

.l-site__footer {
}
