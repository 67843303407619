.hero {
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	position: relative;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.hero {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}
}

@media (min-width: 1404px) {
	/*
		Hero has a max width of 1380px.
		Everything between 1024px and reaching this max width has a padding of 12px on each side.
		1380px + 24px = 1404px
		From this point the padding is based on the total width.
	*/
	.hero {
		padding-right: calc((100% - 1380px) / 2);
		padding-left: calc((100% - 1380px) / 2);
	}
}

.hero__container {
	aspect-ratio: 2 / 3;
	display: flex;
	position: relative;
}

/* from-small */
@media (min-width: 768px) {
	.hero__container {
		aspect-ratio: 3 / 2;
	}
}

.hero__cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: -1;
}

@supports (object-fit: cover) {
	.hero__cover {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.hero__cover-gradient {
	background-blend-mode: multiply;
	background-image: linear-gradient(to bottom, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 0.006) 7%, rgb(0 0 0 / 0.024) 13.9%, rgb(0 0 0 / 0.052) 20.8%, rgb(0 0 0 / 0.088) 27.6%, rgb(0 0 0 / 0.13) 34.4%, rgb(0 0 0 / 0.176) 41.1%, rgb(0 0 0 / 0.225) 47.8%, rgb(0 0 0 / 0.275) 54.4%, rgb(0 0 0 / 0.324) 61.1%, rgb(0 0 0 / 0.37) 67.6%, rgb(0 0 0 / 0.412) 74.2%, rgb(0 0 0 / 0.448) 80.7%, rgb(0 0 0 / 0.476) 87.1%, rgb(0 0 0 / 0.494) 93.6%, rgb(0 0 0 / 0.5) 100%);
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 8.25rem;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.hero__cover-gradient {
		height: 9.25rem;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.hero__cover-gradient {
		height: 10.5rem;
	}
}

.hero__content {
	position: absolute;
	bottom: 1.25rem;
	width: 100%;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.hero__content {
		bottom: 2.25rem;
	}
}

.hero__content__columns {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.hero__content__columns {
		display: flex;
		justify-content: flex-end;
	}
}

.hero__content__column {
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.hero__content__column {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.hero__content__column {
		width: calc(calc(calc(100% - calc(11 * var(--column-gap-from-large))) / calc(12 / 6)) + calc(calc(6 - 1) * var(--column-gap-from-large)));
	}
}

.hero__content__text {
	color: var(--color-white);
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.hero__content__text {
		width: calc(calc(calc(100% - calc(6 * var(--column-gap))) / calc(6 / 5)) + calc(calc(5 - 1) * var(--column-gap)));
	}
}

/* from-large */
@media (min-width: 1440px) {
	.hero__content__text {
		width: calc(calc(calc(100% - calc(6 * var(--column-gap-from-large))) / calc(6 / 4)) + calc(calc(4 - 1) * var(--column-gap-from-large)));
	}
}

.hero[data-video-is-light] .hero__content__text {
	color: var(--color-black);
}

.hero__content__text p {
	/* uses type-e typography */
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.38;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.hero__content__text p {
		font-size: 1.25rem;
		line-height: 1.5;
	}
}

.hero__content__text p:last-child {
	margin-bottom: 0;
}

/* from-wide,desktop */
@media (min-width: 1280px) {
	.hero__content__text p {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}
}

.hero__content__text .arrow-link {
	color: var(--color-white);
}

.hero[data-video-is-light] .hero__content__text .arrow-link {
	color: var(--color-black);
}

.hero__content__watch-button {
	margin-block-start: 1.25rem;
}

.hero__content__toggle {
	position: absolute;
	right: 16px;
	bottom: -0.1875rem;
}

/* from-small,tablet-p */
@media (min-width: 768px) {
	.hero__content__toggle {
		right: 54px;
	}
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.hero__content__toggle {
		right: 12px;
	}
}

/* from-large */
@media (min-width: 1440px) {
	.hero__content__toggle {
		right: 24px;
	}
}

.hero__content__toggle__button {
	-mrh-resets: button;
	background-color: transparent;
	color: var(--color-white);
	display: flex;
	justify-content: center;
	align-items: center;
	transition:
		color 96ms linear,
		background-color 96ms linear;
	width: 1.5rem;
	height: 1.5rem;
}

.hero__content__toggle__button span {
	background-color: currentColor;
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	width: 0.625rem;
	height: 0.625rem;
}

.hero[data-video-is-light] .hero__content__toggle__button span {
	background-color: var(--color-black);
}

.hero__content__toggle__button__pause {
	display: none;
	mask-image: url("../images/pause.svg");
}

.hero__content__toggle[data-playing] .hero__content__toggle__button__pause {
	display: block;
}

.hero__content__toggle__button__play {
	display: block;
	margin-left: 0.125rem;
	mask-image: url("../images/play.svg");
}

.hero__content__toggle[data-playing] .hero__content__toggle__button__play {
	display: none;
}
