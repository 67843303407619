.modal-dialog {
	box-sizing: border-box;
	content-visibility: hidden;
	display: none;
	opacity: 0;
	outline: none;
	overflow-y: auto;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-modal-dialog);
}

.l-styleguide ~ .modal-dialog {
	--z-index-modal-dialog: 9999999;
}

.modal-dialog[data-state="opening"],
.modal-dialog[data-state="open"],
.modal-dialog[data-state="closing"] {
	content-visibility: visible;
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.modal-dialog__inner {
	width: 100%;
	height: auto;
	min-height: 100%;
}
