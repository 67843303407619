.l-error {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* #region Inner */
.l-error__scroll-container {
	box-sizing: border-box;
	display: flex;
	overflow-y: auto;
	padding-right: var(--space-32);
	padding-left: var(--space-32);
	justify-content: center;
	width: 100%;
	max-height: 100%;
}

.l-error__inner {
	padding-top: var(--space-32);
	padding-bottom: var(--space-32);
	max-width: 31.25rem;
}
/* #endregion Inner */

/* #region Header */
.l-error__header {
}

.l-error__logo {
	color: var(--color-black);
	display: block;
	text-decoration: none;
	width: 5.312rem;
	height: 1.562rem;
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-error__logo {
		width: 6.375rem;
		height: 1.875rem;
	}
}

.l-error__logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.l-error__title {
	margin-top: var(--space-32);
}
/* #endregion Header */

/* #region Main */
.l-error__main {
	margin-top: var(--space-24);
}

/* from-medium,tablet-l */
@media (min-width: 1024px) {
	.l-error__main {
		margin-top: var(--space-32);
	}
}
/* #endregion Main */
